<template>
  <modal
    transition="nice-modal-fade"
    width="680"
    :adaptive="true"
    :reset="true"
    height="auto"
    :name="modalName"
  >
    <div class="modal--wrapper">
      <div class="back--wrapper" v-if="$mq === 'xs'">
        <div class="back--button" @click="close()">
          <img src="@/assets/img/icons/back-2.svg" />
        </div>
        {{ $t('booking.modal.initTour.title') }}
      </div>
      <section-loader :show="loading" />
      <section class="modal--container accept--survey-container">
        <div class="desc">
          {{ $t('booking.modal.initTour.message') }}
        </div>
        <div class="date--time">
          <div class="single--row">
            <div class="left--col">{{ $t('booking.modal.initTour.date') }}</div>
            <div class="right--col">{{ tourDateView }}</div>
          </div>
          <div class="single--row">
            <div class="left--col">{{ $t('general.selectHour') }}</div>
            <div class="right--col">{{ time }}</div>
          </div>
        </div>
        <hr class="separator" />
        <div class="d-flex justify-content-between" :class="{ 'flex-wrap': $mq === 'xs' }">
          <div class="btn btn-primary--outline main--btn" @click="close">
            {{ $t('general.btn.back') }}
          </div>
          <div class="btn btn-primary main--btn" @click="reqTour">
            {{ $t('booking.tourSect.initBtn') }}
          </div>
        </div>
      </section>
      <button type="button" class="close" @click="close" v-if="$mq !== 'xs'">
        <img src="@/assets/img/icons/close-1.svg" />
      </button>
    </div>
  </modal>
</template>

<script>
import { mapState } from 'vuex';
const SectionLoader = () => import('@/components/content-loading/section-loading');

export default {
  name: 'initiate-confirmation-modal',
  components: { SectionLoader },
  data() {
    return {
      modalName: 'modal-initiate-tour-confirmation',
    };
  },
  computed: {
    ...mapState({
      listing: state => state.listing.listing,
      date: state => state.v2.rentTransaction.initiateTour.date,
      loading: state => state.v2.rentTransaction.initiateTour.loading,
    }),
    tourDateView() {
      const tourDate = this.$store.state.v2.rentTransaction.initiateTour.date;
      return this ? this.$date.format(tourDate, 'dd MMMM yyyy') : null;
    },
    time: {
      get() {
        // console.log('Time value: ', this.$store.state.booking.initiateTour.time);
        return this.$store.state.v2.rentTransaction.initiateTour.time;
      },
      set(value) {
        this.$store.commit('v2/rentTransaction/initiateTour/SET_TIME', value);
      },
    },
  },
  methods: {
    close() {
      this.$modal.hide(this.modalName);
    },
    async reqTour() {
      // fbq('track', 'Schedule');
      try {
        await this.$store.dispatch('v2/rentTransaction/initiateTour/submit');
        this.$emit('actionDone');
        this.close();
        // eslint-disable-next-line no-empty
      } catch (e) {
        console.log('ERROR REQUEST TOUR: ', e);
      }
    },
  },
};
</script>

<style scoped></style>
